import React, { useMemo, useState } from 'react';
import { format, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import { TimePicker } from '@mui/x-date-pickers';
import {
  driverReport,
  driverSummaryReport,
  listCarrierTransactionsService,
  listFuelTaxReport,
} from '@/fleet/services/transactions';
import { ExportDataAsCSV } from '@/fleet/components/helpers/csv/exporter';
import { Mixpanel } from '@/fleet/config/mixpanel';
import logo from '@/fleet/logo.png';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  OutlinedInput,
  Chip,
} from '@mui/material';
import useGetDrivers, { IDriver } from '@/fleet/hooks/useGetDrivers';
import { uniq } from 'lodash';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { defaultEndDate, defaultStartDate } from '../../helpers/time';

const boxStyle = {
  width: 540,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 2,
  overflowY: 'auto',
  outline: 'none',
};

const closeStyle = {
  height: 40,
  textTransform: 'none',
  color: '#181818',
  padding: '15px',
};

const submitStyle = {
  background: '#181818',
  height: 40,
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#353535',
  },
  padding: '15px',
};

const dateRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginY: 1,
  paddingX: 1,
  paddingY: 0.5,
};

const REPORT_TYPES = {
  TRANSACTIONS: 'transactions',
  FUEL_TAX: 'fuel_tax',
  ALL_DRIVERS: 'all_drivers_report',
  DRIVER_GRAND_TOTAL: 'driver_grand_total_report',
};

const FILE_TYPES = {
  CSV: 'csv',
  PDF: 'pdf',
  XLS: 'xls',
};

export const PRODUCT_CATEGORY_DIESEL = 'PRODUCT_CATEGORY_DIESEL';
export const PRODUCT_CATEGORY_REEFER = 'PRODUCT_CATEGORY_REEFER';
export const PRODUCT_CATEGORY_DEF = 'PRODUCT_CATEGORY_DEF';
export const PRODUCT_CATEGORY_OIL = 'PRODUCT_CATEGORY_OIL';
export const PRODUCT_CATEGORY_ADDITIVES = 'PRODUCT_CATEGORY_ADDITIVES';
export const PRODUCT_CATEGORY_MERCHANDISE = 'PRODUCT_CATEGORY_MERCHANDISE';
export const PRODUCT_CATEGORY_CASH_ADVANCE = 'PRODUCT_CATEGORY_CASH_ADVANCE';

const categories = [
  {
    value: PRODUCT_CATEGORY_DIESEL,
    label: 'Diesel',
  },
  {
    value: PRODUCT_CATEGORY_REEFER,
    label: 'Reefer',
  },
  {
    value: PRODUCT_CATEGORY_DEF,
    label: 'Def',
  },
  {
    value: PRODUCT_CATEGORY_OIL,
    label: 'Oil',
  },
  {
    value: PRODUCT_CATEGORY_ADDITIVES,
    label: 'Additives',
  },
  {
    value: PRODUCT_CATEGORY_MERCHANDISE,
    label: 'Merchandise',
  },
  {
    value: PRODUCT_CATEGORY_CASH_ADVANCE,
    label: 'Cash Advance',
  },
];
export function getByValue(typeValue: string) {
  return categories.find((x) => x.value === typeValue);
}

type Column = {
  name: string;
  label: string;
};

type ExportDataProps = {
  data: any[]; // Adjust this type based on your actual data structure
  columns: Column[];
  fileName: string;
};

type TransactionRecord = {
  driverName?: string;
  quantity?: { value: string };
  unitRetailCost?: { value: string };
  unitDiscountedCost?: { value: string };
  grandTotal?: string;
  transactionNumber?: string;
  [key: string]: any;
};

type GroupedData = {
  [driverName: string]: TransactionRecord[];
};

type TotalKeys =
  | 'Driver Grand Total'
  | 'Total Savings'
  | 'Diesel Gallons'
  | 'Diesel Total Dollars'
  | 'Reefer Gallons'
  | 'Reefer Total Dollars'
  | 'DEF Gallons'
  | 'DEF Total Dollars'
  | 'Cash Advance Total'
  | 'Motor Oil Total Dollars';

type Totals = {
  'Driver Grand Total': number;
  'Total Savings': number;
  'Diesel Gallons': number;
  'Diesel Total Dollars': number;
  'Reefer Gallons': number;
  'Reefer Total Dollars': number;
  'DEF Gallons': number;
  'DEF Total Dollars': number;
  'Cash Advance Total': number;
  'Motor Oil Total Dollars': number;
};

const exportDataAsPDF = ({ data, columns, fileName }: ExportDataProps) => {
  const doc = new jsPDF('l', 'pt'); // 'l' for landscape, 'pt' for points

  const img = new Image();
  img.src = logo;
  const logoHeight = 60;

  // Add the new column to the columns array
  const updatedColumns = [
    ...columns.slice(
      0,
      columns.findIndex((col) => col.name === 'savings'),
    ),
    { name: 'savingsPerGallon', label: 'Savings Per Gallon' },
    ...columns.slice(columns.findIndex((col) => col.name === 'savings')),
  ];

  const headers = updatedColumns.map((col) =>
    col.name === 'savings' ? 'Total Savings' : col.label,
  );

  const groupedData = groupDataByDriver(data);

  const allDriversTotals: Totals = {
    'Driver Grand Total': 0,
    'Total Savings': 0,
    'Diesel Gallons': 0,
    'Diesel Total Dollars': 0,
    'Reefer Gallons': 0,
    'Reefer Total Dollars': 0,
    'DEF Gallons': 0,
    'DEF Total Dollars': 0,
    'Cash Advance Total': 0,
    'Motor Oil Total Dollars': 0,
  };

  Object.entries(groupedData).forEach(([driverName, driverData], index) => {
    if (index > 0) {
      doc.addPage();
    }

    doc.addImage(img, 'PNG', 20, 10, 50, 50);

    doc.setFontSize(16);
    doc.text(`Driver: ${driverName}`, 20, logoHeight + 20);

    const rows = driverData.map((record) => createRow(record, updatedColumns));

    autoTable(doc, {
      head: [headers],
      body: rows,
      startY: logoHeight + 40,
      margin: { left: 20, right: 20 },
      theme: 'striped',
      headStyles: {
        fillColor: '#181818',
        textColor: '#FFFFFF',
        fontSize: 10,
      },
      bodyStyles: { valign: 'middle', fontSize: 9 },
      styles: {
        overflow: 'linebreak',
        cellWidth: 'auto',
        minCellHeight: 15,
      },
    });

    const totals = calculateTotals(driverData);
    addTotalBoxes(doc, totals);

    // Accumulate totals for all drivers
    Object.keys(allDriversTotals).forEach((key) => {
      if (key in totals) {
        allDriversTotals[key as TotalKeys] += totals[key as TotalKeys] || 0;
      }
    });
  });

  // Add a final page with totals for all drivers
  doc.addPage();

  // Center the summary box both horizontally and vertically
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const boxWidth = 300;

  // Add logo
  doc.addImage(logo, 'PNG', 20, 10, 50, 50);

  // Add page title
  doc.setFontSize(16);
  doc.text('All Drivers Summary', pageWidth / 2, 80, { align: 'center' });

  const estimatedBoxHeight =
    (Object.keys(allDriversTotals).length + 1) * 20 + 10;
  const startX = (pageWidth - boxWidth) / 2;
  const startY = (pageHeight - estimatedBoxHeight) / 2;

  type TotalsWithReportGrandTotal = Omit<Totals, 'Driver Grand Total'> & {
    'Report Grand Total': number;
  };

  const finalSummary: TotalsWithReportGrandTotal = {
    ...Object.entries(allDriversTotals).reduce((acc, [key, value]) => {
      if (key === 'Driver Grand Total') {
        acc['Report Grand Total'] = value;
      } else {
        acc[key as keyof Omit<Totals, 'Driver Grand Total'>] = value;
      }
      return acc;
    }, {} as TotalsWithReportGrandTotal),
  };

  addSummaryBox(
    doc,
    finalSummary as unknown as Totals,
    startX,
    startY,
    boxWidth,
  );

  doc.save(fileName);
};

const groupDataByDriver = (data: TransactionRecord[]): GroupedData => {
  return data.reduce((acc: GroupedData, record) => {
    const driverName = record.driverName || 'Unknown Driver';
    if (!acc[driverName]) {
      acc[driverName] = [];
    }
    acc[driverName].push(record);
    return acc;
  }, {});
};

const createRow = (
  record: TransactionRecord,
  columns: Column[],
): (string | number)[] => {
  return columns.map((col) => {
    let value: string | number | undefined = '';

    switch (col.name) {
      case 'savings':
        const quantity = parseFloat(record?.quantity?.value || '0');
        const unitRetailCost = parseFloat(record?.unitRetailCost?.value || '0');
        const unitDiscountedCost = parseFloat(
          record?.unitDiscountedCost?.value || '0',
        );
        const savings =
          !isNaN(quantity) &&
          !isNaN(unitRetailCost) &&
          !isNaN(unitDiscountedCost)
            ? (unitRetailCost - unitDiscountedCost) * quantity
            : 0;
        value = savings.toFixed(2);
        break;
      case 'savingsPerGallon':
        const unitRetailCost2 = parseFloat(
          record?.unitRetailCost?.value || '0',
        );
        const unitDiscountedCost2 = parseFloat(
          record?.unitDiscountedCost?.value || '0',
        );
        const savingsPerGallon =
          !isNaN(unitRetailCost2) && !isNaN(unitDiscountedCost2)
            ? unitRetailCost2 - unitDiscountedCost2
            : 0;
        value = savingsPerGallon.toFixed(2);
        break;
      case 'grandTotal':
        const total = parseFloat(record?.grandTotal || '0');
        value = total.toFixed(2);
        break;
      case 'transactionNumber':
        value = record?.transactionNumber
          ? record.transactionNumber.replace(/^0+/, '')
          : '';
        break;
      default:
        const keys = col.name.split('.');
        let currentValue: any = record;
        for (const key of keys) {
          if (currentValue && typeof currentValue === 'object') {
            currentValue = currentValue[key];
          } else {
            currentValue = '';
            break;
          }
        }
        value = currentValue;
    }
    // ensure we return the correct data
    return value !== undefined && value !== null ? value.toString() : '';
  });
};

const addTotalBoxes = (doc: jsPDF, totals: Totals) => {
  const totalRows = Object.entries(totals)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => [key, value.toFixed(2)]);

  const totalBoxWidth = 300;
  const totalBoxHeight = (totalRows.length + 1) * 20 + 10;
  const startY = (doc as any).lastAutoTable.finalY + 20;
  const startX = (doc.internal.pageSize.width - totalBoxWidth) / 2;

  const pageHeight = doc.internal.pageSize.height;
  const logoHeight = 60; // Define logoHeight here

  if (startY + totalBoxHeight > pageHeight) {
    doc.addPage();
    doc.addImage(logo, 'PNG', 20, 10, 50, 50);
    autoTable(doc, {
      head: [['Item', 'Value']],
      body: totalRows,
      startY: logoHeight + 20,
      theme: 'striped',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: '#181818',
        textColor: '#FFFFFF',
        fontSize: 10,
      },
      tableWidth: totalBoxWidth,
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'left' },
        1: { cellWidth: 'auto', halign: 'right' },
      },
      didDrawPage: () => {
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.rect(startX, logoHeight + 20, totalBoxWidth, totalBoxHeight);
      },
      margin: { left: startX },
    });
  } else {
    autoTable(doc, {
      head: [['Item', 'Value']],
      body: totalRows,
      startY: startY,
      theme: 'striped',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: '#181818',
        textColor: '#FFFFFF',
        fontSize: 10,
      },
      tableWidth: totalBoxWidth,
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'left' },
        1: { cellWidth: 'auto', halign: 'right' },
      },
      didDrawPage: () => {
        // Draw box around totals
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.rect(startX, startY, totalBoxWidth, totalBoxHeight);
      },
      margin: { left: startX },
    });
  }
};

// Update the existing calculateTotals function
const calculateTotals = (data: TransactionRecord[]): Totals => {
  const totals: Totals = {
    'Driver Grand Total': 0,
    'Total Savings': 0,
    'Diesel Gallons': 0,
    'Diesel Total Dollars': 0,
    'Reefer Gallons': 0,
    'Reefer Total Dollars': 0,
    'DEF Gallons': 0,
    'DEF Total Dollars': 0,
    'Cash Advance Total': 0,
    'Motor Oil Total Dollars': 0,
  };

  data.forEach((record) => {
    const quantity = parseFloat(record?.quantity?.value || '0');
    const unitRetailCost = parseFloat(record?.unitRetailCost?.value || '0');
    const unitDiscountedCost = parseFloat(
      record?.unitDiscountedCost?.value || '0',
    );
    const total = parseFloat(record?.grandTotal || '0');

    const savings =
      !isNaN(quantity) && !isNaN(unitRetailCost) && !isNaN(unitDiscountedCost)
        ? (unitRetailCost - unitDiscountedCost) * quantity
        : 0;

    if (!isNaN(savings)) totals['Total Savings'] += savings;
    if (!isNaN(total)) totals['Driver Grand Total'] += total;

    if (record?.productName && typeof record.productName === 'string') {
      const productNameLower = record.productName.toLowerCase();
      if (
        productNameLower.includes('diesel') &&
        !productNameLower.includes('exhaust')
      ) {
        if (!isNaN(quantity)) totals['Diesel Gallons'] += quantity;
        if (!isNaN(total)) totals['Diesel Total Dollars'] += total;
      } else if (productNameLower.includes('reefer')) {
        if (!isNaN(quantity)) totals['Reefer Gallons'] += quantity;
        if (!isNaN(total)) totals['Reefer Total Dollars'] += total;
      } else if (
        productNameLower.includes('def') ||
        productNameLower.includes('exhaust')
      ) {
        if (!isNaN(quantity)) totals['DEF Gallons'] += quantity;
        if (!isNaN(total)) totals['DEF Total Dollars'] += total;
      } else if (productNameLower.includes('cash advance')) {
        if (!isNaN(total)) totals['Cash Advance Total'] += total;
      } else if (productNameLower.includes('motor oil')) {
        if (!isNaN(total)) totals['Motor Oil Total Dollars'] += total;
      }
    }
  });

  return totals;
};

const addSummaryBox = (
  doc: jsPDF,
  totals: Totals,
  startX: number,
  startY: number,
  boxWidth: number,
) => {
  autoTable(doc, {
    head: [['Item', 'Value']],
    body: Object.entries(totals)
      .filter(([, value]) => value > 0)
      .map(([key, value]) => [key, value.toFixed(2)]),
    startY: startY,
    theme: 'striped',
    styles: { fontSize: 10, cellPadding: 5, lineWidth: 0.1 },
    headStyles: { fillColor: '#181818', textColor: '#FFFFFF', fontSize: 10 },
    tableWidth: boxWidth,
    columnStyles: {
      0: { cellWidth: 'auto', halign: 'left' },
      1: { cellWidth: 'auto', halign: 'right' },
    },
    margin: { left: startX },
    didDrawCell: (data) => {
      if (
        data.section === 'body' &&
        data.row.index === data.table.body.length - 1
      ) {
        const boxHeight = data.cell.y + data.cell.height - startY;
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.rect(startX, startY, boxWidth, boxHeight);
      }
    },
  });
};

type ExportTransactionsModalProps = {
  handleClose: () => void;
  availableUnitNumbers: string[];
};

const ExportTransactionModal = ({
  handleClose,
  availableUnitNumbers,
}: ExportTransactionsModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [loading, setLoading] = useState<boolean>(false);
  const [driverUuid, setDriverUuid] = useState<string | string[]>('');
  const [unitNumber, setUnitNumber] = useState('');
  const [exportReportType, setExportReportType] = useState<string | null>(null);
  const [exportFileType, setExportFileType] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const { carrierUuid } = useParams();
  const { drivers } = useGetDrivers(carrierUuid);

  const driverSummaryExportOptions = useMemo(() => {
    return [{ firstName: 'All Drivers', lastName: '' }, ...drivers];
  }, [drivers]);

  const defaultDriverAutocompleteProps = {
    options: driverSummaryExportOptions,
    getOptionLabel: (
      option: IDriver | { firstName: string; lastName: string },
    ) => `${option.firstName} ${option.lastName}`,
  };

  const defaultUnitNumberAutocompleteProps = {
    options: uniq(availableUnitNumbers),
    getOptionLabel: (option: string) => option,
  };

  const runReport = (
    cuuid: string,
    sDate: string,
    eDate: string,
    selectedCategories: string[],
  ) => {
    switch (exportReportType) {
      case REPORT_TYPES.TRANSACTIONS:
        return listCarrierTransactionsService(
          cuuid,
          sDate,
          eDate,
          driverUuid,
          unitNumber,
          selectedCategories,
        );
      case REPORT_TYPES.FUEL_TAX:
        return listFuelTaxReport(cuuid, sDate, eDate);
      case REPORT_TYPES.ALL_DRIVERS:
        return driverReport(cuuid, sDate, eDate);
      case REPORT_TYPES.DRIVER_GRAND_TOTAL:
        return driverSummaryReport(cuuid, driverUuid, sDate, eDate);
      default:
        return listCarrierTransactionsService(cuuid, sDate, eDate);
    }
  };

  const handleSelectProduct = (value: string | string[]) => {
    if (selectedCategories.includes(value)) {
      return;
    }

    setSelectedCategories(value);
  };

  const exportTransactions = async () => {
    const startStr = startDate !== null ? format(startDate, 'MM-dd-yyyy') : '';
    const endStr = endDate !== null ? format(endDate, 'MM-dd-yyyy') : '';

    const sDate = startDate !== null ? startDate.toISOString() : '';
    const eDate = endDate !== null ? endDate.toISOString() : '';
    const cuuid = carrierUuid !== undefined ? carrierUuid.toString() : '';

    runReport(cuuid, sDate, eDate, selectedCategories)
      .then((response) => {
        let data: { name: string; label: string }[] = [];
        let data_minimal: { name: string; label: string }[] = [];

        if (exportReportType === REPORT_TYPES.FUEL_TAX) {
          data = [
            { name: 'date', label: 'Date' },
            { name: 'time', label: 'Time' },
            { name: 'state', label: 'State' },
            { name: 'productName', label: 'Product Name' },
            { name: 'productCode', label: 'Product Code' },
            { name: 'quantity', label: 'Quantity' },
            { name: 'unitNumber', label: 'Unit Number' },
          ];
        } else if (exportReportType === REPORT_TYPES.ALL_DRIVERS) {
          data = [
            { name: 'transaction.driverName', label: 'Name' },
            { name: 'transaction.driverNumber', label: 'ID' },
            { name: 'transaction.quantity.value', label: 'Quantity' },
            {
              name: 'transaction.unitRetailCost.value',
              label: 'Unit Retail Price',
            },
            {
              name: 'transaction.unitDiscountedCost.value',
              label: 'Unit Discount Price',
            },
            {
              name: 'transaction.productRetailTotal.value',
              label: 'Retail Total',
            },
            {
              name: 'transaction.productDiscountedTotal.value',
              label: 'Savings',
            },
            { name: 'transaction.grandTotal', label: 'Grand Total' },

            { name: 'total.totalDiscounted', label: 'Driver Total' },
          ];
        } else if (exportReportType === REPORT_TYPES.DRIVER_GRAND_TOTAL) {
          data = [
            { name: 'driverName', label: 'Name' },
            { name: 'driverExternalId', label: 'ID' },
            { name: 'totalDiscounted', label: 'Discount Total' },
            { name: 'totalGallons', label: 'Total Gallons' },
            { name: 'totalRetail', label: 'Retail Total' },
            { name: 'totalSavings', label: 'Total Savings' },
          ];
        } else if (exportReportType === REPORT_TYPES.TRANSACTIONS) {
          data = [
            { name: 'date', label: 'Date' },
            { name: 'time', label: 'Time' },
            { name: 'transactionNumber', label: 'Transaction Number' },
            { name: 'merchantId', label: 'Merchant Id' },
            { name: 'truckStopName', label: 'Truckstop Name' },
            { name: 'storeNumber', label: 'Store Number' },
            { name: 'city', label: 'City' },
            { name: 'region', label: 'Region' },
            { name: 'productName', label: 'Product Name' },
            { name: 'productCode', label: 'Product Code' },
            { name: 'quantity.value', label: 'Quantity' },
            { name: 'unitRetailCost.value', label: 'Unit Retail Cost' },
            {
              name: 'unitDiscountedCost.value',
              label: 'Unit Discounted Cost',
            },
            { name: 'fee', label: 'Fee Amount' },
            {
              name: 'productRetailTotal.value',
              label: 'Product Retail Cost',
            },
            {
              name: 'productDiscountedTotal.value',
              label: 'Product Discounted Total',
            },
            {
              name: 'discountTotal.value',
              label: 'Discount Total',
            },
            {
              name: 'grandTotal',
              label: 'Grand Total',
            },
            { name: 'unitNumber', label: 'Unit Number' },
            { name: 'trailerNumber', label: 'Trailer Number' },
            { name: 'driverNumber', label: 'Driver Number' },
            { name: 'driverName', label: 'Driver Name' },
            { name: 'authorizationNumber', label: 'Auth Number' },
            { name: 'inNetworkIndicator', label: 'In Network Indicator' },
            { name: 'division', label: 'Division' },
            { name: 'tripNumber', label: 'Trip Number' },
            { name: 'odometer', label: 'Odometer' },
            { name: 'pumpNumber', label: 'Pump Number' },
          ];

          data_minimal = [
            { name: 'date', label: 'Date' },
            { name: 'unitNumber', label: 'Truck#' },
            { name: 'trailerNumber', label: 'Trailer#' },
            { name: 'driverName', label: 'Driver Name' },
            { name: 'driverNumber', label: 'Driver ID' },
            { name: 'city', label: 'City' },
            { name: 'region', label: 'State' },
            { name: 'productName', label: 'Product' },
            { name: 'unitRetailCost.value', label: 'Retail Price' },
            { name: 'unitDiscountedCost.value', label: 'Discounted Price' },
            { name: 'savings', label: 'Savings' }, // (calculated on frontend)
            { name: 'grandTotal', label: 'Final Total' },
            { name: 'transactionNumber', label: 'Transaction Number' },
          ];
        }
        if (response.data) {
          if (exportReportType === REPORT_TYPES.DRIVER_GRAND_TOTAL) {
            ExportDataAsCSV({
              data: response.data.summaries,
              columns: data,
              fileName: `${startStr}-${endStr}-${exportReportType}-report.csv`,
              fromJson: true,
            });
          } else if (
            exportFileType === FILE_TYPES.CSV ||
            exportFileType === null
          ) {
            ExportDataAsCSV({
              data: response.data.transactionRecords || response.data,
              columns: data,
              fileName: `${startStr}-${endStr}-${exportReportType}-report.csv`,
              fromJson: true,
            });
          } else if (exportFileType === FILE_TYPES.PDF) {
            exportDataAsPDF({
              data: response.data.transactionRecords,
              columns: data_minimal,
              fileName: `${startStr}-${endStr}-${exportReportType}-report.pdf`,
            });
          } else if (exportFileType === FILE_TYPES.XLS) {
            const filteredTransactionRecords =
              response.data.transactionRecords.map((record: any) => {
                const filteredRecord: any = {};
                data_minimal.forEach((col) => {
                  const keys = col.name.split('.');
                  if (keys.length > 1) {
                    filteredRecord[col.label] = keys.reduce(
                      (acc, key) => acc && acc[key],
                      record,
                    );
                  } else {
                    filteredRecord[col.label] = record[col.name];
                  }
                });
                return filteredRecord;
              });
            const worksheet = XLSX.utils.json_to_sheet(
              filteredTransactionRecords,
              {
                header: data_minimal.map((col) => col.label),
              },
            );

            const workbook = XLSX.utils.book_new();
            worksheet['!cols'] = data_minimal.map(() => ({ wch: 20 }));

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(
              workbook,
              `${startStr}-${endStr}-${exportReportType}-report.xlsx`,
            );
          }
          setLoading(false);
          Mixpanel.track(`${exportReportType}_exported`);
          handleClose();
        }
      })
      .catch((e: any) => {
        alert(e);
      });
  };

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-title"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={boxStyle}>
        <Typography id="modal-title" variant="h6" component="h1" sx={{ p: 0 }}>
          Export Report
        </Typography>
        <Box mt={2}>
          <Box px={1} py={0.5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
              <Select
                value={exportReportType}
                label="Report Type"
                onChange={(e) => setExportReportType(e.target.value)}
              >
                <MenuItem value={REPORT_TYPES.TRANSACTIONS}>
                  Transactions
                </MenuItem>
                <MenuItem value={REPORT_TYPES.FUEL_TAX}>Fuel Tax</MenuItem>
                <MenuItem value={REPORT_TYPES.ALL_DRIVERS}>
                  All Drivers Report
                </MenuItem>
                <MenuItem value={REPORT_TYPES.DRIVER_GRAND_TOTAL}>
                  Driver Grand Total Report
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {drivers && exportReportType === REPORT_TYPES.DRIVER_GRAND_TOTAL && (
            <Box px={1} my={1}>
              <Autocomplete
                {...defaultDriverAutocompleteProps}
                options={driverSummaryExportOptions}
                disablePortal
                disableClearable
                id="combo-box-demo"
                onChange={(e: any, driver: any) => {
                  if (driver.firstName === 'All Drivers') {
                    setDriverUuid(drivers.map((d) => d.uuid));
                    return;
                  }
                  if (driver) {
                    setDriverUuid([driver.uuid]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Driver" />
                )}
                disabled={exportReportType !== REPORT_TYPES.DRIVER_GRAND_TOTAL}
              />
            </Box>
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={dateRowStyle}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <TimePicker
                label="Start Time"
                value={startDate}
                onChange={(newValue) => {
                  isValid(newValue) && setStartDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Box>
            <Box sx={dateRowStyle}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <TimePicker
                label="End Time"
                value={endDate}
                onChange={(newValue) => {
                  isValid(newValue) && setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Box>
          </LocalizationProvider>

          {drivers && exportReportType === 'transactions' ? (
            <Box px={1} py={0.5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  File Type{' '}
                </InputLabel>
                <Select
                  value={exportFileType || FILE_TYPES.CSV}
                  label="File Type"
                  onChange={(e) => setExportFileType(e.target.value)}
                >
                  <MenuItem value={FILE_TYPES.CSV}>CSV</MenuItem>
                  <MenuItem value={FILE_TYPES.XLS}>XLS</MenuItem>
                  <MenuItem value={FILE_TYPES.PDF}>PDF</MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : null}

          {drivers && exportReportType === 'transactions' ? (
            <>
              <Box mb={2} px={1} pt={1}>
                <FormControl fullWidth>
                  <InputLabel id="multiple-checkbox-label">
                    Included Product Categories (optional)
                  </InputLabel>
                  <Select
                    fullWidth
                    name="selectedCategories"
                    multiple
                    value={selectedCategories}
                    onChange={(e) => handleSelectProduct(e.target.value)}
                    input={
                      <OutlinedInput label="Included Product Categories (optional)" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map((value: any) => {
                          const apt = getByValue(value);
                          return <Chip key={value} label={apt && apt.label} />;
                        })}
                      </Box>
                    )}
                  >
                    {categories.map((x, idx) => (
                      <MenuItem key={idx} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box px={1} mb={2}>
                <Autocomplete
                  {...defaultDriverAutocompleteProps}
                  options={drivers}
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e: any, driver: any) => {
                    if (driver) {
                      setDriverUuid(driver.uuid);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Driver (optional)" />
                  )}
                  disabled={exportReportType !== 'transactions'}
                />
              </Box>
              <Box px={1} mb={2}>
                <Autocomplete
                  {...defaultUnitNumberAutocompleteProps}
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e: any, unitNumber: string | null) => {
                    if (unitNumber) {
                      setUnitNumber(unitNumber);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Unit Number (optional)" />
                  )}
                  disabled={exportReportType !== 'transactions'}
                />
              </Box>
            </>
          ) : null}
        </Box>
        <Box display="flex" m={1} justifyContent="flex-end">
          <Button sx={closeStyle} onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            size="small"
            color="secondary"
            sx={submitStyle}
            onClick={() => {
              setLoading(true);
              exportTransactions();
            }}
            loading={loading}
            loadingPosition="start"
            startIcon={<DownloadIcon />}
            disabled={Boolean(!startDate || !endDate) || !exportReportType}
            variant="contained"
          >
            Export
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportTransactionModal;
